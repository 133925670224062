export default function debounce(callback, threshold) {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(args);
    }, threshold);
  };
}
