Vue

<template>
  <div class="navbar" :class="{ 'hide': navHidden }">
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="relative flex h-16 items-center justify-between">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <!-- Mobile menu button-->
            <DisclosureButton
              class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex flex-shrink-0 items-center">
              <img class="h-8 w-auto" :src="site.favicon" :alt="site.siteTitle" />
              <h1 class="ml-2 text-white font-bold text-lg">
                {{ site.siteLabel }}
              </h1>
              <h2 class="sm:hidden text-white ml-2 font-bold text-lg">
                {{ currentNavigationLabel }}
              </h2>
            </div>
            <div class="hidden sm:ml-6 sm:block">
              <div class="flex space-x-4">
                <NuxtLink v-for="item in navigation" :key="item.name" :to="item.href"
                  :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium']"
                  :aria-current="item.current ? 'page' : undefined">{{ item.name }}</NuxtLink>
              </div>
            </div>
          </div>
          <FilterSource ref="filterSource" />
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="space-y-1 px-2 pb-3 pt-2">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href"
            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']"
            :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <span class="loader-top" v-if="isLoaderVisible"></span>
  </div>
</template>

<script lang="ts" async setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useEventListener, useWindowSize } from '@vueuse/core'
import useDebounce from "@/composables/useDebounce"
import useLoader from "@/composables/useLoader"

interface Props {
}
const props = defineProps<Props>()

const {
  site,
  menu,
  language,
} = await useData()

const route = useRoute()

const navigation = computed(() => {
  return [{
    name: language.value.allCategoryName,
    href: '/',
    current: !route.params.slug
  },
    ...menu.value.map(m => ({
      name: m.title,
      href: `/${m.slug}`,
      current: route.params.slug === m.slug
    }))
  ]
})


const currentNavigationLabel = computed(() => {
  return navigation.value.find(x => x.current)?.name || null
})


const { height } = useWindowSize()

const lastScroll = ref(0)
const navHidden = ref(true);
const filterSource = ref(null)

watch(height, () => {
  if (height.value >= 750) {
    navHidden.value = false;
  }
}, {
  immediate: true
})

watch(navHidden, (n) => {
  if (n) {
    filterSource.value?.close?.();
  }
})

useEventListener('scroll', useDebounce(() => {
  if (height.value < 750) {
    const offset = window.scrollY - lastScroll.value;
    if (offset > 50 && navHidden.value === false) {
      navHidden.value = true;
    }
    if (offset < -50 && navHidden.value === true) {
      navHidden.value = false;
    }
    lastScroll.value = window.scrollY;
  }
}, 50))

const { isLoaderVisible } = useLoader()

</script>

<style scoped>
svg {
  fill: white;
}

.navbar {
  position: sticky;
  transition: top 200ms ease-in-out;
    top: 0;
  
    &.hide {
      top: -65px;
    }
  }
</style>
